interface FunctionsCardProps {
  description: React.ReactNode;
  list: string[];
  showItem: number;
  number: number;
}
export const FunctionsCard = ({
  description,
  list,
  showItem,
  number,
}: FunctionsCardProps) => {
  return (
    <div
      className={`flex flex-col items-center justify-center ${
        showItem === number ? "" : "hidden"
      }`}
    >
      <div className=" w-full p-2 mx-2 border-l-2 border-t-2 border-r-2 border-primary-300 overflow-hidden rounded-t-2xl lg:ml-0 flex flex-col ">
        <div className="w-full mt-2 px-4 md:px-8 animate_animated animate__bounceIn text-muted-500 dark:text-muted-400">
          <div>{description}</div>
          <ul className="text-start mt-5 list-none">
            {list.map((item, index) => (
              <li
                key={index}
                className="relative pl-5 mt-2 before:content-['•'] before:font-bold before:text-xl before:text-primary-500 before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
